.vendorListArea {
  box-sizing: border-box;
  margin: 2rem;
  overflow: auto;
  max-height: 400px;
}
 @media screen and (min-width:0px) and (max-width:960px) {
  .createVendorTabs{
    line-height: 30px;
  }
  .inventoryPageFilterGrid{
    overflow-x: scroll;
  }
} 
.createVendorTabs > *{
  line-height: 40px;
}
.inventoryPageFilterGrid{
  display: flex;
  z-index: 3;
  box-sizing: border-box;
  margin: 1rem;
  margin-bottom: 0rem;
  min-height:28%;
  /* overflow-x: scroll; */
}
.stickyFirstColumn{
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  background-color: inherit;
}
.vendorListTable {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 12px;
}
.vendorListHeader {
  background-color: #fff;
  /* position: sticky; */
  top: 0;
  border-collapse: separate;
  border-spacing: 0 1px;
  border-bottom: 10px solid #eee;
  box-shadow: 0 0 2px 2px #d3d3d3;
}
.vendorListHeading {
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  color: #14c76a;
  padding: 10px 20px;
  cursor: pointer;
}
@media screen and (min-width: 1400px) {
  .vendorListHeading {
    padding: 15px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .vendorListHeading {
    padding: 15px 30px;
  }
}
.vendorListHeadingActions {
  color: #14c76a;
  font-weight: 500;
  font-size: 12px;
  text-align: end;
  padding: 10px 20px;
}
@media screen and (min-width: 1400px) {
  .vendorListHeadingActions {
    padding: 15px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .vendorListHeadingActions {
    padding: 15px 30px;
  }
}
.vendorList {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  color: #666;
  font-size: 12px;
  background-color: white;
  border-top: 5px solid #eee;
}
.vendorListExpanded {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-size: 20px;
  color: #14c76a;
  color: #333;
  font-size: 16px;
  background-color: #fff;
  border-top: 5px solid #eee;
}
.vendorListInfo {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
}
@media screen and (min-width: 1400px) {
  .vendorListInfo {
    padding: 10px 30px;
  }
}
@media screen and (min-width: 1800px) {
  .vendorListInfo {
    padding: 10px 30px;
  }
}
.vendorListInfoActions {
  padding: 10px 20px;
  text-align: end;
  cursor: pointer;
}
.vendorListAddress {
  padding: 10px 20px;
  cursor: pointer;
  line-height: 1rem;
  font-size: 12px;
}
.vendorActionImages {
  box-sizing: border-box;
  padding: 2px 4px;
  height: 1rem;
  cursor: pointer;
}
@media screen and (min-width: 1400px) {
  .vendorActionImages {
    padding: 2px 4px;
    height: 1rem;
  }
}
@media screen and (min-width: 1800px) {
  .vendorActionImages {
    padding: 4px;
    height: 1.25rem;
  }
}
.vendorActionImages:hover {
  background-color: #f3f3f3;
  border-radius: 5px;
}
.vendorListInnerTableWrapper {
  background-color: #fff;
  height: 4rem;
  padding: 0 5rem 20px;
}
.vendorListInnerTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #eee;
  color: #333;
}
.vendorListInnerTableHeader {
  color: white;
  font-weight: normal;
  font-size: 12px;
  font-weight: 500;
  background-color: #eee;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.vendorListInnerTableHeading {
  padding: 10px;
  color: #14c76a;
}
.vendorListInnerTable {
  border-bottom: 3px solid white;
  font-size: 12px;
}
.vendorListInnertableInfo {
  padding: 10px;
  margin-left: 0px;
}
.createVendorGeneralGrid {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(5, 4rem);
}
.createVendorTabArea {
  box-shadow: 0 1px 5px 1px #d3d3d3;
  padding-bottom: 10px;
  margin-top: 40px;
}
.createVendorTabs {
  display: flex;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.createVendorIndividualTab {
  flex: 1;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #e4e4e4;
  cursor: pointer;
text-align: center;
}
.createVendorIndividualTabSelected {
  flex: 1;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
 /* padding: 10px; */
 display: flex;
 align-items: center;
 justify-content: space-evenly;
  color: #1f43e5;
  background-color: #fff;
  border: 2px solid #fff;
  border-bottom: 2px solid #1f43e5;
  font-weight: 500;
text-align: center; 
}
.createVendorIndividualTab:hover {
  flex: 1;
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  font-weight: 500;
}
.createVendorIndividualTabDetails {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  padding: 20px;
  grid-template-rows: repeat(2, 4rem);
}
.createVendorIndividualTabAdditionalDetails {
  display: grid;
  gap: 10px 30px;
  grid-template-columns: repeat(16, 1fr);
  padding: 20px;
  grid-template-rows: repeat(4, 4rem);
}
.createVendorContactsTable {
  margin: 10px auto 0;
  width: 96%;
  max-width: 96%;
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0 3px;
}
.createVendorContactsTableHeader {
  background-color: #f1f1f1;
  color: #14c76a;
  font-size: 12px;
  font-weight: 600;
}
.createVendorContactsTableHeader td {
  padding: 5px 10px;
}
.createVendorContactsTableRows {
  background-color: #f1f1f1;
  font-size: 12px;
}
.createVendorContactsTableRows td {
  padding: 5px 10px;
}
.createVendorContactsAction {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  cursor: pointer;
}
.vendorPhoto {
  box-sizing: border-box;
  color: #666;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  grid-column: auto / span 3;
  grid-row: auto / span 3;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}
.vendorLogo {
  margin-top: 5px;
  border: none;
  border: 1px solid #c8c8c8;
  flex: 1;
  background-color: #d3d3d3;
  border-radius: 2px;
  max-width: 100%;
  object-fit: contain;
  max-height: calc(12rem);
}
#uploadVendorlogo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.multiline {
  grid-column: auto / span 13;
  grid-row: auto / span 3;
}
.multilineInput {
  width: 100%;
  height: calc(12rem);
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  color: #666;
  font-size: 16px;
  flex: 1;
  background-color: white;
  padding-left: 10px;
}
.detailsPageWrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 0 0 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.detailsPageContainer {
  width: 80%;
  margin: 0 auto 4rem;
  flex: 1;
  padding: 20px 20px;
  background-color: white;
}
.invoicePageTitle {
  width: 85%;
  margin: 0 auto 0rem;
  padding: 1px 20px;
  padding-bottom: 15px;
  text-align: center;
  font-weight: bold;
  background-color: white;
}
.invoicePageContainer {
  width: 85%;
  margin: 0 auto 4rem;
  flex: 1;
  padding: 20px 20px;
  background-color: white;
}
.detailsPageNavButtons {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin: 0.75rem auto;
}
.xsmall-font {
  font-size: 9px !important;
}
.small-font {
  font-size: 11px !important;
}
.medium-font {
  font-size: 12px !important; 
}
.align-right {
  text-align: right !important;
}
.align-center{
  text-align: center !important;
}
.location-list-table,.location-list-table > thead > tr >td , .location-list-table > tbody >tr > td {
 text-align: center;
 padding: 5px;
 border-spacing: 0px;
 border-collapse: collapse;
 border: 1px solid black;
 font-size: 16px;
 background-color: white;
}

.location-list-table >thead >tr  >td {
  color: #14c76a;
  font-weight: 550;
}
.vendorSummary {
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.vendorImage {
  box-sizing: border-box;
  background-color: white;
  border: 2px solid #d3d3d3;
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  object-fit: contain;
}

.vendorHeaderImage{
  box-sizing: border-box;
  background-color: white;
  border: 2px solid #d3d3d3;
  width: 90px;
  height: 80px;
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin-left: 20px;
}
.vendorCurrentStatus {
  width: 100%;
  text-align: center;
}
.vendorStatus {
  font-size: 16px;
  font-weight: bold;
  color: #029402;
}
.vendorSummaryText {
  margin: 0 20px;
  font-size: 14px;
}
.vendorSummaryName {
  font-size: 20px;
  font-weight: bold;
}
.vendorSummaryAddress {
  font-size: 20px;
  font-weight: bold;
  text-align: end;
  padding-bottom: 20px;
}
.vendorSummaryAddressValue {
  word-wrap: normal;
  text-align: end;
  margin-bottom: 10px;
}
.vendorSummaryRole {
  font-size: 16px;
  color: #14c76a;
  font-weight: 600;
}
.vendorSummaryGrid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px 5px;
}
.vendorSummaryCategory {
  font-weight: 600;
  padding-right: 5px;
  color: #666;
  font-size: 13px;
}
.vendorSummaryValue {
  word-wrap: normal;
  color: #000;
  font-size: 13px;
}
.vendorDetailTabArea {
  width: 100%;
  margin: 40px auto 0;
  box-shadow: 0 1px 5px 1px #d3d3d3;
  background-color: white;
  overflow-y: auto;
  margin-bottom: 50px;
}
.vendorDetailTabs {
  display: flex;
  text-align: center;
}
.vendorAdditionalInfo {
  margin: 20px auto;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 30px 3fr;
  gap: 20px 5px;
  padding: 50px;
}
.vendorDetailContactsTable {
  margin: 25px auto;
  width: 95%;
  border-collapse: collapse;
  border-spacing: 0 3px;
  background-color: #dfdfdf;
  box-shadow: 2px 2px -5px 5px;
  border: 2px solid #dfdfdf;
  border-radius: 10px;
}
.invoiceDetailContactsTableHeader {
  background-color: white;
  color: #14c76a;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #dfdfdf;
}
.invoiceDetailContactsTableHeader td {
  padding: 10px 5px;
  text-align: center;
}
.vendorDetailContactsTableHeader {
  background-color: white;
  color: #14c76a;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #dfdfdf;
}
.vendorDetailContactsTableHeader td {
  padding: 15px 10px;
}
.vendorDetailContactsTableRows {
  background-color: white;
  font-size: 12px;
  border-bottom: 1px solid #dfdfdf;
}
.vendorDetailContactsTableRows td {
  padding: 8px 10px;
}
.invoiceDetailContactsTableHeader {
  background-color: white;
  color: #14c76a;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #dfdfdf;
}
.invoiceDetailContactsTableHeader td {
  padding: 15px 10px;
  border-right: 0.1px solid #000;
}
.invoiceDetailContactsTableRows {
  background-color: white;
  font-size: 12px;
  border-bottom: none !important;
}
.invoiceDetailContactsTableRows td {
  padding: 8px 10px;
  border-right: 0.1px solid #000;
  border-bottom: none;
}

.invoiceBill, .invoiceBill > tbody > tr >td, .invoiceBill > thead > tr >td{
  border: 0.25px solid black;
  border-collapse: collapse;
  padding: 10px 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.innerTable, .innerTable > tbody > tr >td, .innerTable > thead > tr >td {
  border: 0.1px solid black;
  border-collapse: collapse;
  padding: 2px 1px;
  font-family: Arial, Helvetica, sans-serif;
}

.lineItemTable, .lineItemTable > tbody > tr >td, .lineItemTable > thead > tr >td {
  border: 0.1px solid black;
  border-collapse: collapse;
  border-bottom: none;
  padding: 2px 1px;
  font-family: Arial, Helvetica, sans-serif;
}
.lineItemTable > tbody > tr >td {
  border-bottom: none;
}

.listPageParamsGrid{
  margin: 0px;
  z-index: 3;
  max-height: 40px;
}
.listPageParams{
  flex-basis: 200px;
}


.listPageParamsGrid > :last-child{
  min-height: 60px;
}

 @media screen and (min-width: 1800px) {
  .listPageParamsGrid > *{
    /* max-height: 40px; */
    line-height: 12px;
  }
} 
@media screen and (max-width:860px) {
  .listPageParamsGrid > :last-child{
    min-height: 120px;
  }
}
.dashboardGrid {
    box-sizing: border-box;
    padding: 0.5rem;
    display: grid;
    gap: 20px;
    white-space: nowrap;
    overflow-x: scroll;
    margin: -0.1rem 2rem;
    width: 95%;
    grid-template-columns: 50% 1fr;
}

.dashboardCard {
    background-color: white;
    box-shadow: 0 0 10px 2px #dcdcdc;
    border-radius: 5px;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
}

.dashboardCardHeader {
    display: flex;
    white-space: pre-wrap
}

.dashboardCardTitle {
    flex: 1;
    line-height: 2rem;
    font-size: 12px;
    color: #666;
    font-weight: bold;
    text-align: center;
    position: sticky
}

.dashboardCardBody {
    display: flex;
}

.dashboardAlertBody {
    flex: 1;
    margin: 0 1rem 1rem;
    overflow: auto;
    box-sizing: border-box
}

.dashboardGraphBody {
    display: flex;
    flex: 1;
    margin: 0 0rem;
    overflow: hidden;
    box-sizing: border-box;
}

.dashboardParams{
    display: flex;
    padding: 0.3rem;
    flex-direction: row;
    margin: 1px 0px 0px 1px;
    /* z-index: 1; */
    width: 195%;
    max-height: 20vh;
    grid-column: 1 /  auto;
    grid-row: 1 / 1;
}
.dashboardParams > div {
    width: 85%;
    margin: 6px;
    position: relative;
}
/* @media screen and (min-width:1030px) {
    .dashboardParams{
        position: sticky;
        top: 1px;
    }
} */
.itemTrendList{
    /* height: 250 px; */
    grid-area: auto / 2 / span 3 / span 2;
    padding: 0px;
    display: flex;
    width: inherit;
    max-width: 39vw;
    flex-direction: column;
    grid-area: 2/2/span 2/2 span 2 ;
}
.cardsContainer{
    grid-column: 1 /  span 1;
    align-self: flex-start;
    grid-auto-flow: column;
    display: grid;
    grid-row: auto /span 1;
    grid-column-gap: 35px;
}
@media screen and (max-width:960px) {
    .dashboardParams{
        max-height: 20vh;
    }
    .cardsContainer{
        grid-auto-flow: dense;
        grid-area: auto /1/span 1/ span 1;
    }
}
.dashSingle {
    margin: 0.5rem 0;
    padding: 0.2rem 0;
    color:rgb(10 181 113);
    flex: 1;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    font-family: ui-monospace;
}
.dashBoardToolTip{
    font-size: 12px;
    font-weight: 500;
    color: black;
    background-color: #95ebbf;
    border-radius: 4px;
    padding: 5px;
    font-family: ui-monospace;
}
.alertStockLine {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 0.5rem 1rem;
    background-color:#027439d1;
    margin: 0 0 0.5rem 0;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: 12px;
}

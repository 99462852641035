body {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Noto Sans", "Montserrat", "Antic Slab", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
    font-family: "Poppins";
}

/* @media only screen and (orientation: portrait) {
    .completePage {
        display: none;
        visibility: collapse;
    }
    .warning-message {
        visibility: visible;
    }
}

@media only screen and (orientation: landscape) {
    .warning-message {
        display: none;
        visibility: collapse;
    }
} */

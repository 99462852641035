.multilineInput {
  width: 100%;
  height: calc(12rem);
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  color: #666;
  font-size: 16px;
  flex: 1;
  background-color: white;
  padding-left: 10px;
  border-radius: 1px;
}
td {
  text-align: left;
}
input[type="text"] {
  width: -webkit-fill-available;
}

input::-webkit-input-placeholder {
  font-size: x-small;
}

textarea::-webkit-input-placeholder {
  font-size: small;
}

.commonEmailInput {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(221, 221, 221);
  color: #333;
  font-size: 14px;
  flex: 1;
  border-radius: 5px;
  padding: 0 10px;
}

.errorTextArea {
  color: red;
  text-align: left;
}

.infoTextArea {
  color: #14c76a;
  text-align: left;
  font-size: x-small;
}

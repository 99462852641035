.createPurchaseOrderGrid {
    flex: 1;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(3, 4rem)
}

.createSalesOrderGrid {
    flex: 1;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(3, 4rem)
}

.createSalesOrderGridNewCustomer {
    flex: 1;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(5, 4rem)
}

.vendorStoreDetailArea {
    padding-left: 1rem;
    display: flex;
    flex-direction: column
}

.poVendorAddressDetail {
    flex: 1
}

.poStoreAddressDetail {
    flex: 1;
    padding: 1rem 0rem 0 0rem
}

.detailTitle {
    color: #14c76a;
    font-size: 16px;
    font-weight: bold;
    text-align: end
}

.detailText {
    color: #666;
    font-size: 12px;
    padding: 0.5rem 0rem;
    text-align: end
}

.createPurchaseImportGrid {
    display: grid;
    width: 100%;
    gap: 10px 30px;
    grid-template-columns: repeat(23, 1fr)
}

.saveButton {
    width: 9rem;
    margin: 0 auto;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 5px;
    background-color: #fff;
    color: #1f43e5;
    border: 1px solid #1f43e5
}

@media screen and (min-width: 1400px) {
    .saveButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .saveButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1400px) {
    .saveButton {
        margin: 5px
    }
}

@media screen and (min-width: 1800px) {
    .saveButton {
        margin: 5px
    }
}

.declineButton {
    width: 9rem;
    margin: 0 auto;
    padding: 5px 5px;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin: 10px;
    background-color: #f30;
    color: white
}

@media screen and (min-width: 1400px) {
    .declineButton {
        width: 12rem;
        font-size: 12px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .declineButton {
        width: 12rem;
        font-size: 16px;
        margin: 0 auto;
        padding: 7px 5px;
        border-radius: 10px
    }
}

.purchaseOrderSubSectionArea {
    box-shadow: 0 1px 5px 1px #d3d3d3;
    padding-bottom: 10px;
    margin-top: 40px;
    /**/
    /* max-width: 100vw; */
}

.purchaseOrderIndividualItemsArea {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(24, 1fr);
    padding: 20px;
    grid-template-rows: repeat(1, 4rem);
    margin-bottom: 1rem
}

.poNetTotalSurchargeDiv {
    display: flex;
    width: 100%;
    margin: 2rem auto;
    justify-content: space-between;
}

.purchaseInstruction {
    /* flex: 2; */
    padding: 10px 0;
    max-width: 24vw;
}

.poSpacer {
    /* flex: 1 */
    width: 24vw;
}

.poNetTotal {
    /* flex: 2; */
    flex: 1 1;
    max-width: 20vw;
    text-align: end;
    display: flex;
    flex-direction: column
}

.poFormElement {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 4rem);
    gap: 10px 30px
}

.poTotalTitle {
    /* padding: 10px 0; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333
}

.poNetTotalAmount {
    color: #14c76a;
    font-size: 14px;
    font-weight: 500
}

.poListTabs {
    display: flex;
    text-align: center;
    position: sticky;
    font-size: 12px;
    margin: 0.5rem 1rem 0 1rem;
    /* flex-wrap: wrap; */
    min-height: 50px;
    min-width: 100%;
    height: auto;
    overflow-x: scroll;
}

@media screen and (min-width: 1400px) {
    .poListTabs {
        font-size: 16px
    }
}

@media screen and (min-width: 1800px) {
    .poListTabs {
        font-size: 16px
    }
}

.poListArea {
    box-sizing: border-box;
    margin: 2rem;
    overflow: auto
}

.poSummaryGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px 5px;
    white-space: nowrap
}

.poVendorImage {
    box-sizing: border-box;
    background-color: white;
    border: 2px solid #d3d3d3;
    width: 10rem;
    height: 10rem;
    /*min-width:10rem;min-height:10rem*/
    ;
    object-fit: contain
}

.poSummaryText {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1
}

.poSummaryText2 {
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    text-align: end;
    flex: 1
}

.poDetailChargesDiv {
    margin: 0.5rem 0
}

.poText {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: space-between
}

.poSummaryValue {
    /*line-height:30px;*/
    font-size: 14px
}

.poNumberText {
    font-size: 18px;
    color: #14c76a;
    font-weight: 600;
    padding-bottom: 1rem
}

.createSalesInvoiceInfo {
    display: flex;
    margin-bottom: 1rem
}

.salesInvoiceCompanyAddress {
    flex: 1
}

.salesInvoiceOurAddress {
    flex: 1
}

.detailTitleLeft {
    color: #14c76a;
    font-size: 16px;
    font-weight: bold
}

.detailTextLeft {
    color: #666;
    font-size: 14px;
    padding: 0.5rem 0rem
}

.createSalesInvoiceGrid {
    flex: 1;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(1, 4rem);
    margin-bottom: 1rem
}

.createInvoiceMultilineInput {
    width: 100%;
    height: calc(12rem);
    box-sizing: border-box;
    border: 1px solid #c8c8c8;
    color: #666;
    font-size: 16px;
    flex: 1;
    background-color: white;
    padding-left: 10px
}

.createSalesInvoiceGrid2 {
    flex: 1;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(1, 4rem);
    margin: 1rem 0
}

.createInwardInput {
    height: 2rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-width: 12rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    text-align: right
}

.createInwardInputError {
    height: 2rem;
    border: 1px solid #ee3f3f;
    border-radius: 5px;
    min-width: 12rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    text-align: right
}

.createInwardMultilineInput {
    width: 35%;
    height: calc(12rem);
    box-sizing: border-box;
    border: 1px solid #c8c8c8;
    color: #666;
    font-size: 16px;
    flex: 1;
    background-color: white;
    padding-left: 10px
}

.createPurchaseImportTabArea {
    box-shadow: 0 1px 5px 1px #d3d3d3;
    padding-bottom: 10px;
    margin-top: 40px
}

.createPurchaseImportTabDetails {
    display: grid;
    gap: 10px 30px;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1,4rem);
    padding: 20px
}

.purchaseSubTitle {
    box-sizing: border-box;
    margin: 1rem 0 1rem;
    font-weight: 500;
    font-size: 12px;
    color: #1f43e5;
    text-transform: uppercase
}

@media screen and (min-width: 1400px) {
    .purchaseSubTitle {
        font-size: 14px
    }
}

@media screen and (min-width: 1800px) {
    .purchaseSubTitle {
        font-size: 14px
    }
}

.createPurchaseImportTab {
    border: 2px solid #fff;
    border-bottom: 2px solid transparent;
    padding: 10px;
    background-color: #e4e4e4;
    cursor: pointer;
    font-weight: 600;
    color: #333
}

.createPurchaseImportTabSelected {
    border: 2px solid #fff;
    border-bottom: 2px solid transparent;
    padding: 10px;
    color: #1f43e5;
    background-color: #fff;
    cursor: pointer;
    border: 2px solid #fff;
    border-bottom: 2px solid #1f43e5;
    font-weight: bold
}

.tabCloseButton {
    margin: auto;
    padding: 5px 10px;
    margin-left: 15px;
    color: red;
    border-radius: 50%;
}

.tabCloseButton:hover {
    padding: 5px 10px;
    /* background-color: #e6e6e6; */
    background-color: #9e9e9e3b;
}

.importTaxSwitch {
    display: inline-block;
    width: 40px;
    height: 1.3rem;
    position: relative
}

.toggle-thumb {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #1f43e5;
    border-radius: 40px;
    cursor: pointer
}

.toggle-thumb:before {
    content: "";
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: 4px;
    border-radius: 50%;
    background-color: #1f43e5;
    -webkit-transition: all 0.4s ease-in-out;
   -moz-transition: all 0.4s ease-in-out;
   -ms-transition: all 0.4s ease-in-out;
   -o-transition: all 0.4s ease-in-out;
   transition: all 0.4s ease-in-out;
}

.importTaxCheckbox {
    opacity: 0;
    width: 0;
    height: 0
}
.toggle-thumb::after{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #1f43e5;
    content: '₹';
    transform: translateX(22px);
}
.importTaxCheckbox:checked + .toggle-thumb:after{
    content: '%';
    transform: translateX(2px);
}
.importTaxCheckbox:checked+.toggle-thumb:before {
    transform: translateX(16px)
}

.purchaseSummaryGrid {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 5px;
    flex: 1;
    background-color: #f3f3f3;
    margin: 1rem
}

.purchaseSummaryGridTitle {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 2rem;
    flex: 1;
    margin: 0 1rem;
    font-size: 12px
}

.purchaseSummaryCategory {
    font-weight: bold;
    padding-right: 5px;
    color: #333;
    font-size: 12px
}

.purchaseSummaryValue {
    font-size: 12px
}

.purchaseSummaryRole {
    font-size: 16px;
    color: #14c76a;
    font-weight: 600;
}
.locationListPage{
    list-style:none;
    display: inline-block;
    height:400px;
    border:2px groove #d0d0d0;
    background-color:white;
    width:600px;
    margin-top: 100px;
    overflow:auto;
}

.locationListPage::-webkit-scrollbar-thumb{
    background: #3e5ce2dd;
}
.locationListPage::-webkit-scrollbar-thumb:hover{
    background: #1f43e5;
}
.purchaseImportSummary{
    margin: 20px;
    max-width: max-content;
    grid-template-columns: 1fr 0.65fr 0.65fr 0.65fr !important;
}
.withSixColumngrid {
    grid-template-columns: 1fr 0.65fr 0.65fr 0.65fr 0.65fr 0.65fr !important;
}
.purchaseImportSummary >span{
    color: #1f43e5 !important;
    margin: 2px !important;
}
.purchaseImportSummary > span.vendorSummaryHeader{
    color: #14c76a !important;
    margin: 2px !important;
    font-weight: 600;
    padding-right: 5px;
    font-size: 14px;
}
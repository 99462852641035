
.menuContainer {
    box-sizing: border-box;
    width: 12rem;
    height: 100vh;
    color: #333;
    border-right: 1px solid #f2e0f7;
    display: flex;
    flex-direction: column;
    transform: translateX(-12rem);
}

.menuExpandGroup {
    box-sizing: border-box;
    height: 2rem;
    border-bottom: 1px solid #f2e0f7;
    display: flex;
}

.companyLogo {
    padding: 0.25rem;
    height: 1.5rem;
    object-fit: contain
}

.companyLogoText {
    height: 1rem;
    padding: 0.25rem 0;
    margin: auto 0
}

.menuExpandImage {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem
}

.menuExpandImage:hover {
    background-color: #f2e0f7;
    cursor: pointer
}

@media screen and (min-width: 1400px) {
    .menuContainer {
        width: 14rem
    }

    .menuExpandGroup {
        height: 3rem
    }

    .companyLogo {
        padding: 0.5rem;
        height: 2rem
    }

    .companyLogoText {
        height: 1.5rem;
        padding: 0.75rem 0;
        margin: auto 0
    }

    .menuExpandImage {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.75rem
    }
}

@media screen and (min-width: 1800px) {
    .menuContainer {
        width: 16rem
    }

    .menuExpandGroup {
        height: 3rem
    }

    .companyLogo {
        padding: 0.5rem;
        height: 2rem
    }

    .companyLogoText {
        height: 1.5rem;
        padding: 0.75rem 0;
        margin: auto 0
    }

    .menuExpandImage {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.75rem
    }
}

.menu2Container {
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-right: 1px solid #f2e0f7;
}
div.menuContainer > * {
    /* transition: left 0.5s ease-in-out; */
    -webkit-transition: all 0.5s ease-in-out;
   -moz-transition: all 0.5s ease-in-out;
   -ms-transition: all 0.5s ease-in-out;
   -o-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
   transform: translateX(12rem);
   background-color: white;
}

div.menu2Container > * {
    -webkit-transition: all 0.5s ease-in-out;
   -moz-transition: all 0.5s ease-in-out;
   -ms-transition: all 0.5s ease-in-out;
   -o-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
   background-color: white;
}
.menuCollapseGroup {
    box-sizing: border-box;
    height: 2rem;
    border-bottom: 1px solid #f2e0f7;
    display: flex;
    background-color: white;
}

@media screen and (min-width: 1400px) {
    .menuCollapseGroup {
        height: 3rem
    }
}

@media screen and (min-width: 1800px) {
    .menuCollapseGroup {
        height: 3rem
    }
}

.menuCollapseImage {
    margin: auto;
    width: 1rem;
    height: 1rem;
    padding: 0.5rem
}

@media screen and (min-width: 1400px) {
    .menuCollapseImage {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.75rem 0.75rem
    }
}

@media screen and (min-width: 1800px) {
    .menuCollapseImage {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.75rem 0.75rem
    }
}

.menuCollapseImage:hover {
    background-color: #f2e0f7;
    cursor: pointer
}

.menuItems {
    flex: 1;
    font-size: 12px;
    color: #333;
    overflow: auto;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: white;
}

@media screen and (min-width: 1400px) {
    .menuItems {
        font-size: 13px
    }
}

@media screen and (min-width: 1800px) {
    .menuItems {
        font-size: 14px
    }
}

.menuItemGroup {
    box-sizing: border-box;
    padding: 0.5rem 0;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 10px;
    font-size: 10px;
}

@media screen and (min-width: 1400px) {
    .menuItemGroup {
        padding: 1rem 0;
        font-size: 12px
    }
}

@media screen and (min-width: 1800px) {
    .menuItemGroup {
        padding: 1rem 0;
        font-size: 13px
    }
}

.menuItemGroup:hover {
    background-color: #f3f5f9;
    cursor: pointer
}

.menuItemGroupSelected {
    box-sizing: border-box;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 10px;
    font-size: 10px;
    padding-bottom: 0.5rem;
}

@media screen and (min-width: 1400px) {
    .menuItemGroupSelected {
        font-size: 12px
    }
}

@media screen and (min-width: 1800px) {
    .menuItemGroupSelected {
        font-size: 13px
    }
}

.menuImage {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem
}

.menuText {
    height: 1rem;
    line-height: 1rem;
    display: inline-block;
    align-items: center
}

.menuTextSelected {
    height: 1rem;
    line-height: 1rem;
    display: inline-block;
    align-items: center
}

.subMenuImage {
    padding: 0.325rem 0.5rem;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    fill: #ce9547
}

@media screen and (min-width: 1400px) {
    .subMenuImage {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .subMenuImage {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

.subMenuText {
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 10px
}

@media screen and (min-width: 1400px) {
    .subMenuText {
        font-size: 12px;
        height: 2rem;
        line-height: 2rem
    }
}

@media screen and (min-width: 1800px) {
    .subMenuText {
        font-size: 13px;
        height: 2rem;
        line-height: 2rem
    }
}

.menuTitle {
    box-sizing: border-box;
    height: 1rem;
    padding: 0 0 0 1rem;
    cursor: pointer;
    display: flex;
    border-radius: 10px
}

.menuTitleDisabled {
    color: #999;
    box-sizing: border-box;
    height: 1rem;
    padding: 0 0 0 1rem;
    border-radius: 0.05rem 0 0 0.05rem;
    cursor: not-allowed;
    display: flex
}

.menuTitleSelected {
    height: 1rem;
    padding: 0.5rem 0 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    display: flex;
    background-color: #f3effe;
    font-size: 10
}

@media screen and (min-width: 1400px) {
    .menuTitleSelected {
        font-size: 12px;
        padding: 1rem 0 1rem 1rem
    }
}

@media screen and (min-width: 1800px) {
    .menuTitleSelected {
        font-size: 13px;
        padding: 1rem 0 1rem 1rem
    }
}

.menuTitleSelected:hover {
    background-color: #f3f5f9
}

.indiMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 10px
}

.indiMenu:hover {
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f5f9;
    color: black
}

.indiMenuImage {
    height: 1rem;
    width: 1rem;
    padding: 0.5rem;
    border-radius: 10px
}

@media screen and (min-width: 1400px) {
    .indiMenuImage {
        padding: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .indiMenuImage {
        padding: 1rem
    }
}

.indiMenuImageSelected {
    height: 1rem;
    width: 1rem;
    padding: 0.5rem;
    border-radius: 10px 10px 0 0;
    margin-bottom: 0.5rem;
    background-color: #f3effe
}

@media screen and (min-width: 1400px) {
    .indiMenuImageSelected {
        padding: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .indiMenuImageSelected {
        padding: 1rem
    }
}

.subMenu {
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 5px;
    margin: 0 0.5rem;
    cursor: pointer;
    display: flex;
    color: #999
}

@media screen and (min-width: 1400px) {
    .subMenu {
        height: 2rem;
        line-height: 2rem;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .subMenu {
        height: 2rem;
        line-height: 2rem;
        border-radius: 10px
    }
}

.subMenu:hover {
    background-color: #f3f5f9;
    cursor: pointer
}

.subMenuSelected {
    box-sizing: border-box;
    height: 1.5rem;
    line-height: 1.5rem;
    background-color: #f3f5f9;
    color: #1f43e5;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    margin: 0 0.5rem;
    font-weight: 500
}

@media screen and (min-width: 1400px) {
    .subMenuSelected {
        height: 2rem;
        line-height: 2rem;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .subMenuSelected {
        height: 2rem;
        line-height: 2rem;
        border-radius: 10px
    }
}

.indiMenuSelected {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 10px;
    padding-bottom: 0.5rem
}

.indiMenu2 {
    display: flex;
    flex-direction: column;
    color: #444;
    border-radius: 5px;
    height: 1.5rem
}

@media screen and (min-width: 1400px) {
    .indiMenu2 {
        height: 2rem;
        border-radius: 10px
    }
}

@media screen and (min-width: 1800px) {
    .indiMenu2 {
        height: 2rem;
        border-radius: 10px
    }
}

.indiMenu2:hover {
    background-color: #f3f5f9;
    cursor: pointer
}

.indiMenuImage2 {
    height: 0.75rem;
    width: 0.75rem;
    padding: 0.325rem
}

@media screen and (min-width: 1400px) {
    .indiMenuImage2 {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .indiMenuImage2 {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

.indiMenu2Selected {
    display: flex;
    flex-direction: column;
    background-color: #f3f5f9;
    border-radius: 5px;
    margin: 0 auto;
    height: 1.5rem
}

@media screen and (min-width: 1400px) {
    .indiMenu2Selected {
        border-radius: 10px;
        height: 2rem
    }
}

@media screen and (min-width: 1800px) {
    .indiMenu2Selected {
        border-radius: 10px;
        height: 2rem
    }
}

.indiMenuImage2selected {
    height: 0.75rem;
    width: 0.75rem;
    padding: 0.325rem
}

@media screen and (min-width: 1400px) {
    .indiMenuImage2selected {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

@media screen and (min-width: 1800px) {
    .indiMenuImage2selected {
        padding: 0.5rem 0.5rem;
        width: 1rem;
        height: 1rem
    }
}

@media screen and (max-width : 1300px) {
    .menuContainer{
        position: absolute;
        z-index: 5 !important;
    }
    .menu2Container{
        position: absolute;
        z-index: 5 !important;
    }
    .detailsContainer{
        margin-left: 70px;
    }
}
